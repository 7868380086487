@mixin shadow-neu {
    box-shadow: 10px 10px 20px rgba(#000, .2),
                -10px -10px 20px rgba(#fff, 1);
}

@mixin shadow-neu-hover {
    box-shadow: 4px 4px 10px rgba(#000, .2),
                -4px -4px 10px rgba(#fff, 1);
}

@mixin shadow-neu-active {
    box-shadow: inset 3px 3px 10px rgba(#000, .2),
                inset -3px -3px 10px rgba(#fff, .8);
}

@mixin shadow-neu-check {
    box-shadow: inset 1px 1px 2px rgba(#000, .3),
                inset -2px -2px 2px rgba(#fff, .9);
}