*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 10px;
}

body {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}