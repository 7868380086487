.box {
    width: 800px;
    padding: 0 50px 60px;
    display: block;
    margin: 100px auto;
    background-color: $base;
    border-radius: 40px;
    box-shadow: 20px 20px 40px rgba(#000, .2),
                -20px -20px 40px rgba(#fff, 1);

    &__title {
        display: block;
        margin: auto;
        text-align: center;
        padding-top: 30px;
        font-weight: 800;
    }

    &__content {
        margin: 80px auto 100px;
        display: block;
        text-align: center;
    }

}